.sb__root {
  width: 100%;
  height: fit-content;
  background-color: #fefefe;
  border-radius: 6px;
  margin-bottom: 2.5rem;
  flex: 1;
  box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.02);
}
.sb__footer {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  cursor: pointer;
  background: unset;
  border: none;
}

.sb__footer > .text,
.sb__footer > .icon {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  margin-left: 8px;
  color: #1e2447;

  mix-blend-mode: normal;
  opacity: 0.7;
}

.sb__new-form {
  display: flex;
  flex: 1;
  height: 45px;
  transition: height 0.3s;
  justify-content: flex-start;
}
.sb__expand {
  height: 350px;
  background: #f9f9f9;
}
.sb__box {
  margin: 0 !important;
  width: 47%;
  background: #f9f9f9 !important;
  height: 45px;
  transition: height 0.3s;
}
.sb__box-expand {
  height: 350px;
}
.sb__form-title {
  margin: 10px 0 0 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
}

.sb__select {
  max-height: 94px !important;
}

.log__select-input {
  background-color: rgba(30, 36, 71, 0.06);
  border: none;
  padding: 6px 0 6px 8px;
  font-size: 12px;
  height: 100%;
  border-radius: 2px;
}
.log__select-input-icon {
  top: 9px;
}
.log__select-input > h3 {
  font-size: 12px;
}

.log__date-input {
  width: 100%;
  background-color: rgba(30, 36, 71, 0.06);
}
.log__date-input-styles>div>div>p {
  position: absolute;
  margin-top: 32px;
}
.log__date-input-styles > div {
  /* border: none; */
  font-size: 12px;
  border-radius: 2px;
  /* padding: 5px 10px; */
}

.sb__date-picker {
  right: 0;
}
.sb__date-picker-up {
  bottom: 30px;
}

/* .floating-box{
  background: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
} */

/* .floating-box .sb__box-expand{
  width: 100%;
}
.floating-box .sb__box{
  background: #fff !important;
} */
