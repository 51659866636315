.ps__plantDetailsPanel {
  display: flex;
  flex: 1;
  padding: 35px;
  height: 100%;
  box-shadow: 13px 13px 26px #d3d3d3, -13px -13px 26px #ededed;
  position: relative;
}
.mapClick-direction {
  width: 280px;
  position: absolute;
  bottom: 30px;
  left: 53%;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid #00C48C; */
  padding: 8px 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 200;
}
.mapClick-direction p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #00C48C;
}

.ps__details-left {
  display: flex;
  flex: 3;
  background: #ffffff;
  overflow: auto;
  border-radius: 4px 0px 0px 6px;
}

.ps__details-left form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ps__details-left header {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}
.ps__details-left h1 {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1px;
  color: #001122;
  mix-blend-mode: normal;
  padding: 30px 0 0 20px;
}

.ps__formInput {
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 25px 25px 25px;
}

.ps__input-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;
  margin-top: 10px;
}
.ps__input-wrap h4 {
  color: var(--error-messages);
  font-weight: 400;
}

.ps__input-wrap label {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: #1e2447;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps__input-wrap input,
.ps__input-wrap textarea {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: rgba(30, 36, 71, 0.8);
  width: 100%;
  height: 32px;
  display: flex;
  background-color: white;
  border: 1px solid rgba(30, 36, 71, 0.15);
  border-radius: 4px;
  padding: 7px 10px;
  margin-top: 4px;
}

.ps__input-wrap input::placeholder,
.ps__input-wrap textarea::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: rgba(30, 36, 71, 0.4);
}
.ps__input-wrap textarea:focus-visible {
  border: 1px solid #e2b7b1;
  outline: none;
}

.ps__input-wrap textarea {
  height: 80px;
  resize: none;
  overflow: auto;
  max-width: 100%;
}

.ps__input-error {
  border: 1px solid var(--error-messages) !important;
}

.ps__select-input {
  border: none;
  padding: 0px !important;
  font-size: 12px;
  height: 100%;
  border-radius: 2px;
}
.ps__select-input-icon {
  top: 4px !important;
}
.ps__select-input > input {
  font-size: 12px;
  margin-top: 0px;
  border: unset !important;
}

.icon-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.ps_submit {
  padding: 25px;
  display: flex;
  flex: 1;
}
.ps_submit button {
  width: 100%;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  font-family: "Roboto" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ps_submit button:nth-child(2) {
  margin-left: 10px;
  color: #8d91a2;
  background: #eff0f2;
}

.ps__map {
  flex: 8;
}
.ps__map > div {
  flex: 1;
  height: 100%;
}
