.cr__new-card {
  height: 195px;
  width: 150px !important;
  background: #fff;
  border: 0.6px solid #e3e4e7;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 13px
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .cr__new-card {
      height: 168px;
      width: 150px !important;
      background: #fff;
      border: 0.6px solid #e3e4e7;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 13px
    }
  }
}

/* Test website on real Safari 11+ */

.cr__new-card h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #9092a3;
  opacity: 0.5;
  margin-top: 15px;
}

.BrainhubCarousel__arrows:disabled {
  visibility: hidden;
}




.makeStyles-carousalContainer-141 {
  flex-wrap: nowrap !important;
  flex: 1 !important;
}

/* .slick-prev:before,
.slick-next:before:disabled {
  display: none !important;
} */

.slick-prev::before,
.slick-next::before {
  opacity: 0 !important;
  font-size: 30px;
  border-radius: 0 !important;
  color: #fefffe;
}

.slick-prev::after,
.slick-next::after {
  color: black;
}


.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0 !important;
}


.slick-track {
  margin: 0 1% !important;
}

.carousel {
  margin: 0px 30px;
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .carousel {
      margin-bottom: 240px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

/* Test website on real Safari 11+ */
