.dc__tables {
  width: 100%;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
}

.white_cell {
  border: 1px solid rgba(30, 36, 71, 0.15);
  background: #fff;
  border-radius: 2px;
  padding: 3px 8px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  line-height: 16px;
}

.row {
  width: 100%;
  height: 24px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dc__tables .stats_col {
  width: 32%;
  text-overflow: ellipsis;
  text-align: left;
}

.dc__tables .col_col {
  width: 64%;
}

.select-box {
  background: #f2f2f4;
  border: none;
  width: 100%;
  padding: 3.6px 8px;
  border-radius: 2px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  line-height: 16px;
  appearance: none;
  -webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
  background-image:url('assets/dropdown_selector.png');
	background-repeat:no-repeat;
  background-position: calc(100% - 8px);
}
.select-box:focus-within {
  outline: none;
  border: none;
}

.dc__tables .header {
  font-weight: 400;
  color: rgb(0, 0, 0, 0.5);
}

.no-data {
  font-size: 14px;
  color: rgb(0, 0, 0, 0.5);
}
