.cp__snack--success > div {
  background-color: white;
  color: #00c48c;
}
.cp__snack--error > div {
  background-color: white;
  color: red;
}
.small-screen{
  max-width: 100vw;
}
.cp__snack--content {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cp__snack--content > p {
  margin-left: 5px;
}

.cp__modal {
  top: 120px !important;
  max-width: 70%;
  left: calc(25rem + 45px) !important;
  outline: none;
}
.cp__root {
  box-shadow: unset;
  border-radius: 6px;
  overflow: hidden;
  outline: none;
}
.cp__modal-main {
  margin: -50px -35px;
  outline: none;
}

.cp__new-project {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  margin: 20% 20%;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  text-align: center;
}
.cp__new-project > h2 {
  color: #1e2447;
  margin-bottom: 16px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2px;
}
.cp__new-project > h3 {
  color: #62667e;
  margin-bottom: 25px;
  font-family: Roboto;
  font-weight: normal;

  font-size: 15px;
  line-height: 26px;
}
.cp__newProject-btn {
  width: 210px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.cp__new-project div {
  display: flex;
  flex-direction: row;
}
.cp__confirm {
  position: relative;
  width: 440px;
  margin: 130px auto auto;
  padding: 50px 20px 30px;
}
.cp__confirm > h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  color: #1E2447;
}
.cp__confirm > h3 {
  font-size: 14px;
  line-height: 26px;
  color: rgba(30, 36, 71, 0.7);
}
.cp__close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.cp__confirm-btn {
  border-radius: 4px;
  height: 34px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cp__cancel-btn {
  background: #eff0f2;
  border: #eff0f2;
  width: 100px;
  color: #1e2447;
  opacity: 0.5;
}
.cp__delete-btn {
  background: #ff647c;
  border: #ff647c;
  width: 120px;
  margin-left: 15px;
  color: #fff;
}
.cp__delete-btn > h3 {
  color: #fff;
  font-weight: 500 !important;
  line-height: 16px;
}
.cp__confirm-btn > h3 {
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  mix-blend-mode: normal;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}
