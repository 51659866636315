.sc__body {
  display: flex;
  flex-direction: column;
  padding: 20px 25px 30px;
  border-bottom: 1px solid rgba(30, 36, 71, 0.1);
}
.sc__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* margin-bottom: 20px; */
}
/* .sc_head1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
} */
.sc__active-row {
  color: #00c48c;
  font-weight: 600;
  letter-spacing: 0.6;
}
.sc__head > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  flex: 1;
}
.sc__head > p {
  font-size: 13px;
  font-weight: 600;
  color: #707085;
}
.sc__head > button {
  display: flex;
  align-items: center;
  background: unset;
  border: unset;
  cursor: pointer;
}
.sc__head > button > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #1e2447;
  mix-blend-mode: normal;
  opacity: 0.7;
}
.sc__head > button > svg {
  margin-right: 8px;
}

.sc__list-wrap {
  border: 0.8px solid #1e2447;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 6px;
}

.sc__list-expand {
  border-top: none;
  border-left: 0.2px solid rgba(30, 36, 71, 0.15);
  border-right: 0.2px solid rgba(30, 36, 71, 0.15);
  border-bottom: 0.2px solid rgba(30, 36, 71, 0.15);
  height: 100%;
  border-radius: 2px 2px 6px 6px;
  background: #f9f9f9;
}

.sc__list-uncollapsed {
  min-height: 330px;
  height: fit-content;
}

.sc__list-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 8.5px 15px;
  align-items: center;
  background: #ffffff;
  border: unset;
  cursor: pointer;
}
.sc__list-head-selected {
  background-color: #111b30;
  border-radius: 2px;
}
.sc__list-head > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  width: 100%;
  /* margin-right: 72px; */
}
.sc__list-head > h3 span {
  /* color: #707085; */
  width: 140px;
  display: inline-block;
  text-align: left;
}
.sc_head_span{
  font-weight: 600;
  opacity: 0.9;
  color: '#1E2447';
  max-width: '100%';
}
.sc_head_span-active{
  color: '#FFFFFF';
}
.sc__list-head-selected > h3 {
  color: #ffffff;
  opacity: 1;
}

.sc__list-head > aside {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.sc__saved {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sc__saved > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0 6px;
}

.sc__box {
  margin: 0;
  width: 100%;
  background: #f9f9f9;
}

.sc__box-collapsed {
  background: #f9f9f9;
  margin: 0;
}

.sc__collapsed > div:not(.textbox-full, .sc__footer-container) {
  width: 31% !important;
  min-width: 0;
  margin-bottom: 20px;
}
.sc__date-root-style {
  background-color: rgba(30, 36, 71, 0.06);
}
.sc__delete-btn {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px 20px 20px;
}

.sc__delete-btn > span {
  margin-left: 4px;
  color: #ff647c;
  opacity: 0.7;
  font-size: 11px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: normal;
}

.new-config-row {
  mix-blend-mode: normal;
  border: 1px solid rgba(30, 36, 71, 0.15);
  background: #ffffff;
  color: rgba(30, 36, 71, 0.7);
  box-sizing: border-box;
  border-radius: 2px;
  text-align: start;
  cursor: pointer;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.7;
}

.sc__body .tm__submit {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  /* padding: 0 25px 25px !important; */
}

.sc__box .db__formInput {
  justify-content: flex-start;
  gap: 0;
  column-gap: 20px;
  padding: 20px 20px 0px;
}

.textbox-full {
  width: 100% !important;
  height: 108px;
  margin-bottom: 25px;
}

.sc__footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 20px;
}
.hover_details:hover {
}
