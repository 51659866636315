.toggle-btn {
    /* padding: 5px 10px; */
    /* padding-left: 150px;
    padding-top: 34px; */
    color: lightgray !important;
    /* width: 28px; */
    /* height: 28px; */
}

.toggle-btn:hover {
    box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78);
    -moz-box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78);-shadow: 1px 1px 18px -2px rgba(120,120,120,0.75);
    transition: 75ms;
    background-color: 'lightgray';
    cursor: pointer;
}

.sensorHistoryTimelineBox{
    /* box-shadow: 4px 1px 15px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 4px 1px 15px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78); */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    background-color: white;
    /* background-color: #F3F6F8; */
    box-shadow: rgb(199 199 199 / 59%) 0px 0px 10px 7px;
    border-radius: 6px;
    padding-bottom: 10px;
}
.towerList{
    color: #1e2447;
    font-size: 18px;
    font-style: bold;
    font-family: Roboto;
    font-weight: 400;
    padding-left: 30px;
    padding-top: 25px;
}