@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
:root {
  /* --dark: #0e1625;
  --primary-main: #1e2447;
  --white: #ffffff; */

  --dark: #0e1625;
  --white: #ffffff;
  --light-gray: #b1b1bd;
  --dark-blue: #1e2447;
  --error-messages: #ff647c;
  --main-cta: #00c48c;
  --darker-blue: #7b96f2;
  --dark-blue-95: #292e4f;
  --lighter-blue: #c4d0f9;
  --dark-blue-90: #333858;
  --dark-blue-85: #3e4461;
  --dark-blue-80: #4a4f6b;
  --faded-blue: #bbc1e4;
  --page-grey-bg: #f9f9f9;
  --grey: #c8c8c8;
  --dark-blue-75: #555973;
  --dark-blue-70: #5f637c;
  --dark-blue-65: #6b6e85;
  --dark-blue-60: #767a8f;
  --dark-blue-55: #818497;
  --dark-blue-50: #8b8ea0;
  --dark-blue-45: #9799a9;
  --dark-blue-40: #a2a4b2;
  --dark-blue-30: #b7b9c3;
  --dark-blue-25: #c3c4cd;
  --dark-blue-15: #d8d9df;
  --dark-blue-20: #cecfd6;
  --dark-blue-10: #e3e4e7;
  --dark-blue-9: #e6e6e9;
  --dark-blue-8: #e8e9ec;
  --dark-blue-7: #eaebed;
  --dark-blue-5: #eeeff1;
  --dark-blue-6: #ededef;
  --dark-blue-4: #f1f1f3;
  --dark-blue-3: #f3f3f4;
  --dark-blue-2: #f6f6f6;
  --dark-blue-1: #f7f7f8;
  --left-side-bar-bg-color: #111b30;
  --timeline-darker-blue: #8e9cea;
  --timeline-lighter-blue: #c2ccff;
  --timeline-general-maintenance: #fc8761;
  --timeline-height-change: #53b09f;
  --styles-brand-theme-accent-action-pri: #37b24d;
  --colors-15-white-subnav-buttons: rgba(255, 255, 255, 0.15);
  --styles-dark-light-theme-action-secon: rgba(5, 61, 109, 0.06);
  --timeline-orientation-change: #b08cf8;
  --primary-bg: #111b30;

  /* custom */
  --page-grey-bg: #f9f9f9;
  --icon-white: #ffffff;
  --jexcel_header_color: #000;
  --jexcel_header_color_highlighted: #000;
  --jexcel_header_background: #f3f3f3;
  --jexcel_header_background_highlighted: #dcdcdc;
  --jexcel_content_color: #000;
  --jexcel_content_color_highlighted: #000;
  --jexcel_content_background: #fff;
  --jexcel_content_background_highlighted: rgba(0,0,0,0.05);
  --jexcel_menu_background: #fff;
  --jexcel_menu_background_highlighted: #ebebeb;
  --jexcel_menu_color: #555;
  --jexcel_menu_color_highlighted: #555;
  --jexcel_menu_box_shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  --jexcel_border_color: #ccc;
  --jexcel_border_color_highlighted: #000;
  --active_color: #007aff;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  font-size: 62.5%;
  overflow: hidden;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  /* line-height: 1.5; */
  font-family: "Roboto", sans-serif;
}

/* img {
  display: block;
  width: 100%;
} */
fieldset,
input {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

button:focus {
  border: none;
  outline: none;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img {
  max-width: 100%;
  display: block;
} */

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url(/static/media/calendar.e43f4617.svg) no-repeat;
  width: 13px;
  padding: 0;
  margin: 0;
  padding-right: 7px;
  height: 13px;
  border-width: thin;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cp__modal,
.MuiDialog-root {
  z-index: 10000000000 !important;
}

.no-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.no-info-icon {
  width: 130px;
  height: 130px;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.no-info-text {
  font-size: 14px;
  color: #c1bebe;
  margin-bottom: 20px;
  text-align: center;
}

.green-select .select__select_main {
  border: 1.5px solid #00c48c;
  background-color: transparent;
  padding: 0px;
}

.green-select input {
  padding: 7px 10px;
}

.green-select input::-webkit-input-placeholder {
  color: #00c48c !important;
}

.green-select input,
.green-select input::placeholder {
  color: #00c48c !important;
}

.green-select svg > g > path {
  fill: #00c48c;
}

.add-box-attribute-container {
  border: 0.8px dashed rgba(30, 36, 71, 0.7);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
}

html p {
  margin-bottom: 0;
}

html * {
  font-family: "Roboto", sans-serif;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6 {
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 10px;
}

.newerTableStyles .MuiDataGrid-window {
  overflow-y: visible !important;
  position: relative;
  overflow-x: hidden !important;
}

/* .newerTableStyles  */
.MuiDataGrid-root .MuiDataGrid-window {
  position: relative !important;
}

.SD_Datagrid .MuiDataGrid-window {
  top: 23px !important;
}
.SD_Datagrid .MuiDataGrid-windowContainer {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.SD_Datagrid .MuiDataGrid-footerContainer {
  margin-top: 27px !important;
}
.SD_Datagrid #mui-74230 {
  font-size: 15px !important;
}

.newerTableStyles .MuiDataGrid-window::-webkit-scrollbar {
  width: 55px;
}

.newerTableStyles .MuiDataGrid-windowContainer {
  height: auto !important;
}

.newTableStyles .MuiDataGrid-dataContainer,
.newTableStyles .MuiDataGrid-viewport {
  height: auto !important;
}

.newTableStyles .MuiDataGrid-viewport {
  max-height: none !important;
}

.newTableStyles .MuiDataGrid-renderingZone {
  max-height: none !important;
  -webkit-transform: none !important;
          transform: none !important;
}

.columnCellStyles {
  display: flex;
  line-height: initial !important;
  flex-direction: column;
}

.newRowStyles {
  max-height: none !important;
  align-items: stretch;
}

.newCellStyles {
  max-height: none !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex !important;
  align-items: center !important;
  line-height: 14px;
  justify-content: center !important;
  width: 100%;

  /* identical to box height, or 108% */
  text-align: center;
  letter-spacing: 0.13125px;
  color: #1e2447;
  opacity: 0.85;
}

.newTableStyles.newerTableStyles {
  border: none !important;
}

.newTableStyles.newerTableStyles .MuiDataGrid-columnsContainer {
  background-color: #111b30;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
}

.newerTableStyles.collapsedTable .MuiDataGrid-columnsContainer {
  width: 100% !important;
}

.newHeaderStyles.newerHeaderStyles svg {
  color: #fff;
}

.newHeaderStyles .MuiDataGrid-columnHeaderTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #1e2447;
}

.newCellStyles.align-left {
  justify-content: flex-start !important;
}

.newTableStyles.newerTableStyles .newRowStyles {
  flex-wrap: wrap;
}

.w-100 {
  width: 100% !important;
}

.no-min-max {
  min-width: auto !important;
  max-width: none !important;
  min-height: auto !important;
  max-height: none !important;
}

.w-0 {
  width: 0 !important;
}

.newHeaderStyles.newerHeaderStyles .MuiDataGrid-columnHeaderTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
  /* identical to box height, or 100% */

  color: #fff;
}

.newHeaderStyles .MuiDataGrid-columnSeparator {
  display: none;
}

.newHeaderStyles.newerHeaderStyles .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px;
}

.newHeaderStyles .MuiDataGrid-iconButtonContainer {
  align-items: center;
}

.newHeaderStyles {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-height-table.MuiDataGrid-root {
  flex: 0 0 56px;
  overflow: hidden;
}

.cursorPointer {
  cursor: pointer;
}

.newCellStyles.align-right {
  justify-content: flex-end !important;
}

.submit-btn {
  padding: 9px 35px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-cancel {
  color: rgba(30, 36, 71, 0.5);
  background: #eff0f2;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.green-select::before {
  height: 100%;
  border-bottom: 1px solid #00c48c !important;
  /* margin-left: -8px; */
  border: 1px solid #00c48c;
}

.green-select::after {
  border-bottom: none !important;
}

.green-select {
  color: #00c48c !important;
}

.green-select > .MuiSelect-icon {
  color: #00c48c;
}

.selectedRowsStyles {
  background: #fff !important;
}

.auto-complete-input-label label {
  color: #000 !important;
}

.auto-complete-input-label-1 {
  border: 0;
}

.auto-complete-input-label-1 label {
  color: #000 !important;
  font-size: 13px;
}

.auto-complete-input-label-1 input {
  color: #000 !important;
  font-size: 13px;
}

.auto-complete-list-items li {
  color: #1e2447;
  opacity: 0.5;
  font-size: 14px;
}

.auto-complete-list-items li:hover {
  opacity: 1;
}

.sentry-error-embed .form-submit .powered-by {
  display: none !important;
}

.Container404 {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.Heading404 {
  font-size: 45px;
}

#homeLink404 {
  margin-top: 15px;
  font-size: 18px;
  text-decoration: none;
}
.marker-labelx {
  background-color: white;
  margin-top: 65px;
  padding: 4px 7px;
  font-size: 15px;
  font-weight: bold;
  color: "#000000";
}
.ExceptionWidth {
  width: 47% !important;
}
.ExceptionWidth48 {
  width: 48% !important;
}
.ExceptionWidth100 {
  width: 100% !important;
  margin-top: 0px !important;
}


.submit-btn {
  background: #00c48c;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-cancel {
  /* margin-left: 10px; */
  background: #eff0f2;
}

.form_section {
  margin: 21px 25px !important;
  background: #ffffff;
  border-radius: 6px;
}
.header-button{
  border: 1px solid;
    padding: 3px 5px;
    border-radius: 5px;
    font-weight: 500;
    color: #1E2447 !important;
    display: flex;
    opacity: 1 !important;
    align-items: center;
}
.header-menu{
  padding: 3px 0px !important;
  display: flex !important;
  grid-gap: 5px !important;
  gap: 5px !important;
  margin: 3px 0px !important;
  font-size: 13px !important;
  min-width: 90px !important;
}
.header-menu a{
  text-decoration: none !important;
  color: black !important;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0px 8px;
  white-space: break-spaces;
  grid-gap: 5px;
  gap: 5px;
  margin-block: 2px;  

}

.header-menu svg{
  min-width: 20px;
}

.header-menu svg path, .header-button svg path{
  stroke: black !important;
  fill: black !important;
}
.ps__box-center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.prism{
  height: 33px;
  -webkit-filter: invert(1);
          filter: invert(1);
  opacity: 0.6;
  margin-bottom: -5px;
  margin-top: -8px;
}
.ps__plantDetailsPanel {
  display: flex;
  flex: 1 1;
  padding: 35px;
  height: 100%;
  box-shadow: 13px 13px 26px #d3d3d3, -13px -13px 26px #ededed;
  position: relative;
}
.mapClick-direction {
  width: 280px;
  position: absolute;
  bottom: 30px;
  left: 53%;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid #00C48C; */
  padding: 8px 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 200;
}
.mapClick-direction p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #00C48C;
}

.ps__details-left {
  display: flex;
  flex: 3 1;
  background: #ffffff;
  overflow: auto;
  border-radius: 4px 0px 0px 6px;
}

.ps__details-left form {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.ps__details-left header {
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  align-items: center;
}
.ps__details-left h1 {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1px;
  color: #001122;
  mix-blend-mode: normal;
  padding: 30px 0 0 20px;
}

.ps__formInput {
  display: flex;
  flex: 8 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 25px 25px 25px;
}

.ps__input-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;
  margin-top: 10px;
}
.ps__input-wrap h4 {
  color: var(--error-messages);
  font-weight: 400;
}

.ps__input-wrap label {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: #1e2447;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps__input-wrap input,
.ps__input-wrap textarea {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: rgba(30, 36, 71, 0.8);
  width: 100%;
  height: 32px;
  display: flex;
  background-color: white;
  border: 1px solid rgba(30, 36, 71, 0.15);
  border-radius: 4px;
  padding: 7px 10px;
  margin-top: 4px;
}

.ps__input-wrap input::-webkit-input-placeholder, .ps__input-wrap textarea::-webkit-input-placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: rgba(30, 36, 71, 0.4);
}

.ps__input-wrap input::placeholder,
.ps__input-wrap textarea::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: rgba(30, 36, 71, 0.4);
}
.ps__input-wrap textarea:focus-visible {
  border: 1px solid #e2b7b1;
  outline: none;
}

.ps__input-wrap textarea {
  height: 80px;
  resize: none;
  overflow: auto;
  max-width: 100%;
}

.ps__input-error {
  border: 1px solid var(--error-messages) !important;
}

.ps__select-input {
  border: none;
  padding: 0px !important;
  font-size: 12px;
  height: 100%;
  border-radius: 2px;
}
.ps__select-input-icon {
  top: 4px !important;
}
.ps__select-input > input {
  font-size: 12px;
  margin-top: 0px;
  border: unset !important;
}

.icon-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.ps_submit {
  padding: 25px;
  display: flex;
  flex: 1 1;
}
.ps_submit button {
  width: 100%;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  font-family: "Roboto" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ps_submit button:nth-child(2) {
  margin-left: 10px;
  color: #8d91a2;
  background: #eff0f2;
}

.ps__map {
  flex: 8 1;
}
.ps__map > div {
  flex: 1 1;
  height: 100%;
}

.rsb__towerSidePane {
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 53.6px;
  height: 100%;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.02);
  background-color: var(--icon-white);
}

.rsb__towerSidePane.width-wide {
  width: 695px;
}
.rsb__towerSidePane.width-narrow {
  width: 250px;
}

@media (max-width: 1600px) {
  .rsb__towerSidePane.width-wide {
    width: 550px;
  }
}
@media (max-width: 1200px) {
  .rsb__towerSidePane.width-wide {
    width: 400px;
  }
}
.rsb__towerSideSubPane {
  display: flex;
  flex-direction: column;
  padding: 25px 20px 0;
}

.towerLocationContainer {
  width: 100%;
  height: 200vh;
  display: flex;
  margin-top: 30px;
  color: white;
}
.towerNameSidePane {
  opacity: 0.3;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #1e2447;
}

.towerLocationPreview {
  margin-top: 5px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #1e2447;
}

.towerNo {
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bdbdbd;
  flex: 1 1;
}

.towerNo p {
  margin-top: 5px;
}

.db_section {
  /* display: flex; */
  flex: 1 1;
  margin: 21px 25px;
  background: #ffffff;
  border-radius: 6px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.db_section article {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.db_section header {
  border-bottom: 1px solid rgba(30, 36, 71, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.no-header header {
  border: 0;
  padding: 0;
}

.db__formInput {
  display: flex;
  flex: 3 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  padding: 21px 25px;
}

.db__formInput.sca__form {
  justify-content: flex-start;
}

.db__input-wrap {
  /* height: 40px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 19px;
  width: 90%;
}

.db__input-wrap p {
  margin-bottom: 0;
  /* position: absolute;
  margin-top: 35px; */
}

.db__input-wrap-view-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.1px;
  color: #1e2447;
  opacity: 0.3;
  margin-bottom: 6px;
}

.db__input-wrap-edit-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 6px;
}

.db__input-inner {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  flex-direction: column;
}

.db__input-inner2 {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

.db__input-view {
  background-color: transparent;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #1e2447;
  opacity: 0.9;
  text-overflow: ellipsis;
}

.db__input2 {
  display: flex;
  color: #000000;
  opacity: 0.9;
  padding-left: 0;
  padding-right: 0;
  width: 600px;
  height: 100%;
  margin: 0;
  padding: 6px 0 6px 8px;
  outline: none;
  border: unset;
  resize: none;
  background: rgba(30, 36, 71, 0.06);
  border-radius: 2px;
  vertical-align: start;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.db__input {
  display: flex;
  color: #000000;
  opacity: 0.9;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 6px 0 6px 8px;
  outline: none;
  border: unset;
  resize: none;
  background: rgba(30, 36, 71, 0.06);
  border-radius: 2px;
  vertical-align: start;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.db__input-error {
  border: 1px solid var(--error-messages) !important;
}

.db__upload {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 42px;
  background: #ffffff;
  /* border: 1px solid rgba(30, 36, 71, 0.15); */
  border-radius: 2px;
  justify-content: flex-start;
  align-items: center;
}
.bor {
  border: 1px solid rgba(30, 36, 71, 0.15);
}
.db__upload > p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  opacity: 0.8;
  margin-left: 10px;
}

@media (max-width: 1500px) {
  .db__input-wrap {
    margin-bottom: 19px;
  }
}

.progress {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}

.sb__root {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fefefe;
  border-radius: 6px;
  margin-bottom: 2.5rem;
  flex: 1 1;
  box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.02);
}
.sb__footer {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  cursor: pointer;
  background: unset;
  border: none;
}

.sb__footer > .text,
.sb__footer > .icon {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  margin-left: 8px;
  color: #1e2447;

  mix-blend-mode: normal;
  opacity: 0.7;
}

.sb__new-form {
  display: flex;
  flex: 1 1;
  height: 45px;
  transition: height 0.3s;
  justify-content: flex-start;
}
.sb__expand {
  height: 350px;
  background: #f9f9f9;
}
.sb__box {
  margin: 0 !important;
  width: 47%;
  background: #f9f9f9 !important;
  height: 45px;
  transition: height 0.3s;
}
.sb__box-expand {
  height: 350px;
}
.sb__form-title {
  margin: 10px 0 0 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
}

.sb__select {
  max-height: 94px !important;
}

.log__select-input {
  background-color: rgba(30, 36, 71, 0.06);
  border: none;
  padding: 6px 0 6px 8px;
  font-size: 12px;
  height: 100%;
  border-radius: 2px;
}
.log__select-input-icon {
  top: 9px;
}
.log__select-input > h3 {
  font-size: 12px;
}

.log__date-input {
  width: 100%;
  background-color: rgba(30, 36, 71, 0.06);
}
.log__date-input-styles>div>div>p {
  position: absolute;
  margin-top: 32px;
}
.log__date-input-styles > div {
  /* border: none; */
  font-size: 12px;
  border-radius: 2px;
  /* padding: 5px 10px; */
}

.sb__date-picker {
  right: 0;
}
.sb__date-picker-up {
  bottom: 30px;
}

/* .floating-box{
  background: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
} */

/* .floating-box .sb__box-expand{
  width: 100%;
}
.floating-box .sb__box{
  background: #fff !important;
} */

.sca__list-wrap {
  border: 1px solid rgba(30, 36, 71, 0.15);
  border-radius: 2px;
  display: flex;
  height: 34px;
  overflow: hidden;
  flex-direction: column;
  /*align-items: space-between;*/
  padding: 0;
  /*margin-right: px;*/
}

.sc__body2 {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
/* 
.sca__list-wrap:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
} */
.sca__saved {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sca__saved > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0 6px;
}
.sca__list-expand {
  /* transition: height 3s ease-in-out; */
  height: auto !important;
  border-top: none;
  /* border-left: 0.2px solid rgba(30, 36, 71, 0.15);
  border-right: 0.2px solid rgba(30, 36, 71, 0.15);
  border-bottom: 0.2px solid rgba(30, 36, 71, 0.15); */
  border-radius: 2px 2px 6px 6px;
  border: none !important;
}
.calibration-InnerContainer {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  flex-wrap: wrap;
}
.sca__form {
  display: flex;
  padding: 20px;
  width: 100%;
  /* justify-content: space-between; */
  grid-gap: 0;
  gap: 0;
  grid-column-gap: 21px;
  -webkit-column-gap: 21px;
          column-gap: 21px;
}
.sca__form-flex {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;

  /* justify-content:; */
}
.sc__delete-cal {
  margin-top: 10px;
}
.ca__input-box {
  /* margin-top: 10px; */
  width: 100%;
  /* height: 40px; */
  /* width: 350px; */
}
.ca__input-box2 {
  /* margin-top: 10px; */
  width: 180px;
}
/*.sca__grey-input {*/
/*  width: 150px;*/
/*}*/

.sca__cal-input {
  border: 2px solid #eee;
  background-color: rgba(30, 36, 71, 0.06);
  width: 180px;
}
.sca__cal-input2 {
  background-color: rgba(30, 36, 71, 0.06);
  width: 150px;
}

.sca__input-box-gr {
  width: 500px !important;
}

.sca__box {
  margin: 0 !important;
  background: inherit;
}

.sca__form > div:not(:last-child, .ExceptionWidth, .ExceptionWidth48, .ExceptionWidth100) {
  width: 30% !important;
  min-width: 0 !important;
}


.sca__form-collapsed.sca__form > div:not(:last-child) {
  /*width: calc(100% - 12.5px) !important; */
  /* width: 100% !important; */
}

.input-textarea-full {
  width: 100%;
}
.sca__form > .db__input-wrap.tm__input-box-full {
  width: 100% !important;
}

.ca__textarea-box {
  margin-top: 10px;
  width: 1100%;
  height: 40px;
}
.inputMargin {
  margin-bottom: 20px;
}
.db__input > .tm__input-box-full.ca__input-box {
  margin-top: 10px;
  width: 1100%;
  height: 40px;
}
.sca__form > .db__input {
  width: 100% !important;
}

.sca__form.sca__form-collapsed > .db__input-wrap.tm__input-box-full {
  width: 79.5% !important;
  height: auto;
  margin-top: 10px;
}
.sca__form.sca__form-collapsed > .db__input-wrap.tm__input-box-full .db__input-inner textarea {
  /* width: 1000px; */
  /* max-width: 500%;
  min-width: 100%; */
  /* height: 108px; */
  width: 1230%;
  height: 30px;
}
.sca__upload-documentation {
  margin-bottom: 7px;
  width: 100% !important;
}
.sca__upload-documentation .db__input-inner {
  align-items: center;
  /* width: 85%; */
}
.sca__upload-documentation .container {
  margin-bottom: 0px;
}
.sca__upload-documentation label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sca__upload-documentation label > button {
  outline: none;
  border: none;
  background: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.sca__upload-documentation label > button > span {
  margin-left: 8px;
}
/* .sca__upload-documentation .db__input-inner2 {
  align-items: center;
  width: 105%
} */
.sca__upload-documentation2 {
  margin-bottom: 7px;
  width: 100% !important;
  background-color: #fff;
}
.sca__upload-documentation2 .db__input-inner {
  align-items: center;
  /* width: 110%; */
}
.sca__upload-documentation2 .container {
  margin-bottom: 0px;
}
.sca__upload-documentation2 label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sca__upload-documentation2 label > button {
  outline: none;
  border: none;
  background: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.sca__upload-documentation2 label > button > span {
  margin-left: 8px;
}

.sca__delete-button {
  height: 100%;
  margin-left: 4px;
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
}

.sca__delete-button > svg {
  font-size: 18px;
}

.activeBox .db__input-inner {
  border: 1px solid #00c48c;
}
.activeBox .db__upload > p {
  color: #00c48c;
  font-size: 14px;
}

.activeBox .container {
  margin-bottom: 0;
}

.activeBox .db__upload {
  height: auto;
  justify-content: flex-start;
  padding: 10px;
}

.updated-at-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.ca__submit-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 20px;
}
.ca__submit {
  width: 70%;
  display: flex;
  align-items: center;
}

.ca__submit-btn {
  position: relative;
  padding: 9px 35px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  text-align: center;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.ca__submit-cancel {
  margin-left: 10px;
  background: #eff0f2;
  color: rgba(30, 36, 71, 0.5);
}
.ca__delete-btn {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px 20px 20px;
}
.ca__delete-btn > span {
  margin-left: 4px;
  color: #ff647c;
  opacity: 0.7;
  font-size: 11px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: normal;
}
#TextAreaIdHeight {
  height: 59px;
}

.ca__input-box-checkbox .db__input-inner{
  width: unset;
}

.ca__input-box-checkbox .db__input-inner span{
  /* width: 100%;
   */
   padding: 3px;
}
/* .sca__form-flex > div:not(:last-child) {
  width: 18% !important;
  min-width: 0 !important;
} */

.cp__snack--success > div {
  background-color: white;
  color: #00c48c;
}
.cp__snack--error > div {
  background-color: white;
  color: red;
}

.cp__snack--content {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cp__snack--content > p {
  margin-left: 5px;
}

.cp__modal {
  top: 120px !important;
  max-width: 70%;
  left: calc(25rem + 45px) !important;
  outline: none;
}
.cp__root {
  box-shadow: unset;
  border-radius: 6px;
  overflow: hidden;
  outline: none;
}
.cp__modal-main {
  margin: -50px -35px;
  outline: none;
}

.cp__new-project {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  margin: 20% 20%;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  text-align: center;
}
.cp__new-project > h2 {
  color: #1e2447;
  margin-bottom: 16px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2px;
}
.cp__new-project > h3 {
  color: #62667e;
  margin-bottom: 25px;
  font-family: Roboto;
  font-weight: normal;

  font-size: 15px;
  line-height: 26px;
}
.cp__newProject-btn {
  width: 210px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.cp__new-project div {
  display: flex;
  flex-direction: row;
}
.cp__confirm {
  position: relative;
  width: 440px;
  margin: 130px auto auto;
  padding: 50px 20px 30px;
}
.cp__confirm > h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  color: #1E2447;
}
.cp__confirm > h3 {
  font-size: 14px;
  line-height: 26px;
  color: rgba(30, 36, 71, 0.7);
}
.cp__close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.cp__confirm-btn {
  border-radius: 4px;
  height: 34px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cp__cancel-btn {
  background: #eff0f2;
  border: #eff0f2;
  width: 100px;
  color: #1e2447;
  opacity: 0.5;
}
.cp__delete-btn {
  background: #ff647c;
  border: #ff647c;
  width: 120px;
  margin-left: 15px;
  color: #fff;
}
.cp__delete-btn > h3 {
  color: #fff;
  font-weight: 500 !important;
  line-height: 16px;
}
.cp__confirm-btn > h3 {
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  mix-blend-mode: normal;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}

.ma__list-wrap {
  border: 1px solid rgba(30, 36, 71, 0.15);
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  transition: height 0.3s;
  overflow: hidden;
  flex-direction: column;
  padding: 0;
  margin-bottom: 6px;
}
.ma__list-expand {
  height: 100%;
  border-radius: 2px 2px 6px 6px;
  background: #f9f9f9;
}
.ma__list-close {
  height: 34px !important;
}

.ma__list-expand-more {
  height: 590px;
}

.ma__date-root-style {
   background-color: rgba(30, 36, 71, 0.06);
}

.ma__delete-btn {
  display: flex;
  align-self: start;
  border: none;
  outline: none;
  padding: 0px 20px 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.ma__delete-btn > span {
  margin-left: 4px;
  color: #ff647c;
  opacity: 0.7;
  font-size: 11px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: normal;
}

.ma__delete-btn-container{
  display: flex;
  /* justify-content: center; */
}

.ma__input-box{
  width: 33%;
} 

.ma__submit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 20px;

}

.ma__submit-btn {
  padding: 9px 35px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ma__submit-cancel {
  margin-left: 10px;
  color: rgba(30, 36, 71, 0.5);
  background: #eff0f2;
}
.sc__body {
  display: flex;
  flex-direction: column;
  padding: 20px 25px 30px;
  border-bottom: 1px solid rgba(30, 36, 71, 0.1);
}
.sc__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* margin-bottom: 20px; */
}
/* .sc_head1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
} */
.sc__active-row {
  color: #00c48c;
  font-weight: 600;
  letter-spacing: 0.6;
}
.sc__head > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  flex: 1 1;
}
.sc__head > p {
  font-size: 13px;
  font-weight: 600;
  color: #707085;
}
.sc__head > button {
  display: flex;
  align-items: center;
  background: unset;
  border: unset;
  cursor: pointer;
}
.sc__head > button > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #1e2447;
  mix-blend-mode: normal;
  opacity: 0.7;
}
.sc__head > button > svg {
  margin-right: 8px;
}

.sc__list-wrap {
  border: 0.8px solid #1e2447;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 6px;
}

.sc__list-expand {
  border-top: none;
  border-left: 0.2px solid rgba(30, 36, 71, 0.15);
  border-right: 0.2px solid rgba(30, 36, 71, 0.15);
  border-bottom: 0.2px solid rgba(30, 36, 71, 0.15);
  height: 100%;
  border-radius: 2px 2px 6px 6px;
  background: #f9f9f9;
}

.sc__list-uncollapsed {
  min-height: 330px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.sc__list-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 8.5px 15px;
  align-items: center;
  background: #ffffff;
  border: unset;
  cursor: pointer;
}
.sc__list-head-selected {
  background-color: #111b30;
  border-radius: 2px;
}
.sc__list-head > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  width: 100%;
  /* margin-right: 72px; */
}
.sc__list-head > h3 span {
  /* color: #707085; */
  width: 140px;
  display: inline-block;
  text-align: left;
}
.sc_head_span{
  font-weight: 600;
  opacity: 0.9;
  color: '#1E2447';
  max-width: '100%';
}
.sc_head_span-active{
  color: '#FFFFFF';
}
.sc__list-head-selected > h3 {
  color: #ffffff;
  opacity: 1;
}

.sc__list-head > aside {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.sc__saved {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sc__saved > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0 6px;
}

.sc__box {
  margin: 0;
  width: 100%;
  background: #f9f9f9;
}

.sc__box-collapsed {
  background: #f9f9f9;
  margin: 0;
}

.sc__collapsed > div:not(.textbox-full, .sc__footer-container) {
  width: 31% !important;
  min-width: 0;
  margin-bottom: 20px;
}
.sc__date-root-style {
  background-color: rgba(30, 36, 71, 0.06);
}
.sc__delete-btn {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px 20px 20px;
}

.sc__delete-btn > span {
  margin-left: 4px;
  color: #ff647c;
  opacity: 0.7;
  font-size: 11px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: normal;
}

.new-config-row {
  mix-blend-mode: normal;
  border: 1px solid rgba(30, 36, 71, 0.15);
  background: #ffffff;
  color: rgba(30, 36, 71, 0.7);
  box-sizing: border-box;
  border-radius: 2px;
  text-align: start;
  cursor: pointer;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.7;
}

.sc__body .tm__submit {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  /* padding: 0 25px 25px !important; */
}

.sc__box .db__formInput {
  justify-content: flex-start;
  grid-gap: 0;
  gap: 0;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 20px 20px 0px;
}

.textbox-full {
  width: 100% !important;
  height: 108px;
  margin-bottom: 25px;
}

.sc__footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 20px;
}
.hover_details:hover {
}

.sd__box-measurement-details,
.sd__box-sensor-details {
  margin: 0;
  width: 100%;
  border-bottom: 1px solid rgba(30, 36, 71, 0.1);
  border-radius: 0px;
}
.sd__box-measurement-details header {
  padding: 20px 15px 0px;
  border-bottom: none;
}
.sd__box-sensor-details header {
  padding: 20px 25px 0px;
  border-bottom: none;
}
.sd__box-measurement-details header > h1,
.sd__box-sensor-details header > h1 {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #1e2447;
  margin-left: 0px;
}
.sd__box-sensor-details-container {
  padding: 14px 25px;
}
.sd__box-container {
  padding: 14px 20px 14px;
}
.sd__md-input-box {
  min-width: 47%;
  margin-bottom: 16px;
}
.sd__sd-input-box {
  width: 30% !important;
  min-width: 30%;
  margin-bottom: 16px;
}

.sd__date-root {
  width: 65px !important;
  border: 1px solid #eee;
}

.sd__date-root > div > div {
  width: 100%;
}

.sd__date-root > div > div > input {
  width: 100%;
}

.sd__date-root-expand {
  width: 100% !important;
  border: 1px solid #eee;
}

.sensor_inputs {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  gap: 16px;
}

.sensor_inputs .db__input-wrap {
  width: 100% !important;
  margin-bottom: 0;
}

.sd__box header {
  border: 0px;
  padding: 0 25px;
}

.data-col-container,
.sensor-config-container,
.mounting-container,
.callibration-container {
  border-top: 1px solid #1e24471a;
}

.data-col-container .stats_col,
.data-col-container .col_col {
  max-width: 210px;
  min-width: 210px;
}

.data-col-container .stats_col {
  margin-right: 25px;
}

#right-preview-sidebar {
  transition: 0.5s ease-in-out;
}

.dc__tables {
  width: 100%;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
}

.white_cell {
  border: 1px solid rgba(30, 36, 71, 0.15);
  background: #fff;
  border-radius: 2px;
  padding: 3px 8px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  line-height: 16px;
}

.row {
  width: 100%;
  height: 24px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dc__tables .stats_col {
  width: 32%;
  text-overflow: ellipsis;
  text-align: left;
}

.dc__tables .col_col {
  width: 64%;
}

.select-box {
  background: #f2f2f4;
  border: none;
  width: 100%;
  padding: 3.6px 8px;
  border-radius: 2px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  line-height: 16px;
  appearance: none;
  -webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACOSURBVHgBrcw9CsJAEAXgN1ltBCvBNo1Y23oDG4+WxsbWY9ikFFtLCeJPEatso0sC2WQnmRQhBNLlwQwz8PGAsUL+ardlYD8ESmdP3udxvhKzBmHRHybO4mcYeaLzdHpkhu42yF+YJJBbyTImsvOlfyOnNkSYCXATG3zfl1+LJP/klTWQ1boGh/geaoyeCuPhOT70Qrt7AAAAAElFTkSuQmCC);
	background-repeat:no-repeat;
  background-position: calc(100% - 8px);
}
.select-box:focus-within {
  outline: none;
  border: none;
}

.dc__tables .header {
  font-weight: 400;
  color: rgb(0, 0, 0, 0.5);
}

.no-data {
  font-size: 14px;
  color: rgb(0, 0, 0, 0.5);
}

.bord{
    border: 1px solid rgba(30, 36, 71, 0.15);
    background-color: #f9f9f9;
    border-top: 0px;
    
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.cp__snack--success > div {
  background-color: white;
  color: #00c48c;
}
.cp__snack--error > div {
  background-color: white;
  color: red;
}
.small-screen{
  max-width: 100vw;
}
.cp__snack--content {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cp__snack--content > p {
  margin-left: 5px;
}

.cp__modal {
  top: 120px !important;
  max-width: 70%;
  left: calc(25rem + 45px) !important;
  outline: none;
}
.cp__root {
  box-shadow: unset;
  border-radius: 6px;
  overflow: hidden;
  outline: none;
}
.cp__modal-main {
  margin: -50px -35px;
  outline: none;
}

.cp__new-project {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  margin: 20% 20%;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  text-align: center;
}
.cp__new-project > h2 {
  color: #1e2447;
  margin-bottom: 16px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2px;
}
.cp__new-project > h3 {
  color: #62667e;
  margin-bottom: 25px;
  font-family: Roboto;
  font-weight: normal;

  font-size: 15px;
  line-height: 26px;
}
.cp__newProject-btn {
  width: 210px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.cp__new-project div {
  display: flex;
  flex-direction: row;
}
.cp__confirm {
  position: relative;
  width: 440px;
  margin: 130px auto auto;
  padding: 50px 20px 30px;
}
.cp__confirm > h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  color: #1E2447;
}
.cp__confirm > h3 {
  font-size: 14px;
  line-height: 26px;
  color: rgba(30, 36, 71, 0.7);
}
.cp__close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.cp__confirm-btn {
  border-radius: 4px;
  height: 34px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cp__cancel-btn {
  background: #eff0f2;
  border: #eff0f2;
  width: 100px;
  color: #1e2447;
  opacity: 0.5;
}
.cp__delete-btn {
  background: #ff647c;
  border: #ff647c;
  width: 120px;
  margin-left: 15px;
  color: #fff;
}
.cp__delete-btn > h3 {
  color: #fff;
  font-weight: 500 !important;
  line-height: 16px;
}
.cp__confirm-btn > h3 {
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  mix-blend-mode: normal;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}



.toggle-btn {
    /* padding: 5px 10px; */
    /* padding-left: 150px;
    padding-top: 34px; */
    color: lightgray !important;
    /* width: 28px; */
    /* height: 28px; */
}

.toggle-btn:hover {
    box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78);
    -moz-box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78);-shadow: 1px 1px 18px -2px rgba(120,120,120,0.75);
    transition: 75ms;
    background-color: 'lightgray';
    cursor: pointer;
}

.sensorHistoryTimelineBox{
    /* box-shadow: 4px 1px 15px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 4px 1px 15px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(117,113,113,0.78); */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    background-color: white;
    /* background-color: #F3F6F8; */
    box-shadow: rgb(199 199 199 / 59%) 0px 0px 10px 7px;
    border-radius: 6px;
    padding-bottom: 10px;
}
.towerList{
    color: #1e2447;
    font-size: 18px;
    font-style: bold;
    font-family: Roboto;
    font-weight: 400;
    padding-left: 30px;
    padding-top: 25px;
}
.gm-style .gm-style-iw-c {
  padding: 15px 0 15px 15px !important;
  width: 210px !important;
  max-height: 135px !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.18) !important;
}
.gm-style .gm-style-iw-d {
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
}
.gm-style .gm-style-iw-c button {
  top: -1.5px !important;
  right: 0 !important;
}
.ProjectName {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1E2447;
}
.ProjectType {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #1E2447;
  margin: 8px 0;
  text-transform: capitalize;
}
.location {
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}
.map__project-link {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.map__project-link span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.1px;
  color: #00C48C;
  margin-right: 6px;
}
.map__loader {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

    .header__dropdown {
        position: relative;
        display: inline-block;
    }
    
    .header__dropdown-content {
        display: none;
        position: absolute;
        min-width: 130px;
        z-index: 100000;
        background: #FFFFFF;
        mix-blend-mode: normal;
        border: 0.5px solid rgba(30, 36, 71, 0.15);
        box-sizing: border-box;
        box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        right: 0;
    }
    
    .header__dropdown-content {
        padding: 0;
        opacity: 1!important;
    }
    
    .header__dropdown-content li {
        padding: 10px 10px;
        text-decoration: none;
        display: block;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #1E2447;
        mix-blend-mode: normal;
        opacity: 0.4;
        cursor: pointer;
    }
    
    .header__dropdown-content li:hover { color: black; opacity: 1;}
    
    .header__dropdown:hover .header__dropdown-content {display: block; opacity: 1;}
    
.cr__new-card {
  height: 195px;
  width: 150px !important;
  background: #fff;
  border: 0.6px solid #e3e4e7;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 13px
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .cr__new-card {
      height: 168px;
      width: 150px !important;
      background: #fff;
      border: 0.6px solid #e3e4e7;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 13px
    }
  }
}

/* Test website on real Safari 11+ */

.cr__new-card h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #9092a3;
  opacity: 0.5;
  margin-top: 15px;
}

.BrainhubCarousel__arrows:disabled {
  visibility: hidden;
}




.makeStyles-carousalContainer-141 {
  flex-wrap: nowrap !important;
  flex: 1 1 !important;
}

/* .slick-prev:before,
.slick-next:before:disabled {
  display: none !important;
} */

.slick-prev::before,
.slick-next::before {
  opacity: 0 !important;
  font-size: 30px;
  border-radius: 0 !important;
  color: #fefffe;
}

.slick-prev::after,
.slick-next::after {
  color: black;
}


.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0 !important;
}


.slick-track {
  margin: 0 1% !important;
}

.carousel {
  margin: 0px 30px;
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .carousel {
      margin-bottom: 240px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

/* Test website on real Safari 11+ */

.card {
  background-color: white;
}

button.BrainhubCarousel__arrows {
  width: 30px;
  height: 30px;
  margin: 82px 0px 82px 0px;
  object-fit: contain;
  border-color: grey !important;
  background-color: white;
}

button.BrainhubCarousel__arrowLeft {
  margin-left: 25px;
  padding-right: 5px;
}

button.BrainhubCarousel__arrowRight {
  padding-left: 5px;
  margin-left: -8px;
  margin-right: 15px;
}

button.BrainhubCarousel__arrows span {
  border-color: grey !important;
}

button.BrainhubCarousel__arrows:hover {
  background-color: white !important;
}

.tc__card {
  display: flex;
  height: 194px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 140px !important;
  margin-right: 30px !important;
  border-radius: 3px !important;
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .tc__card {
      display: flex;
      height: 180px;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      width: 140px !important;
      margin-right: 30px !important;
    }

  }
}

/* Test website on real Safari 11+ */

.tc__none {
  -webkit-transform: translate(0%, -400%);
          transform: translate(0%, -400%);
  font-size: 15px;
  font-weight: 800;
  color: #d3d3d3;
}

.tc__name {
  width: 140px;
  /* padding-bottom:10px; */
  padding-block: 8px;
  text-align: center;
  background-color: var(--dark-blue-90);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc__name-decommissioned {
  width: 150px;
  /* padding-bottom:10px; */
  padding-block: 8px;
  text-align: center;
  background-color: var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}


.tc__text {
  width: 125px;
  height: 16px;
  margin: 1px 0px;
  opacity: 0.9;
  font-family: Roboto;
  font-size: 13px;
  align-content: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: var(--icon-white);
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
}

@media (min-width:1025px) and (max-width:1290px) {
  .tc__card {
    width: 155px !important;
  }

  .tc__name {
    width: 155px;
  }
}

@media (min-width:940px) and (max-width:972px) {

  .tc__card {
    width: 165px !important;
  }

  .tc__name {
    width: 165px;
  }
}



@media screen and (max-width:792px) {

  .tc__card {
    width: 150px !important;
  }

  .tc__name {
    width: 150px;
  }
}

@media screen and (max-width:792px) {

  .tc__card {
    margin: 0 auto !important;
  }


}

@media (min-width:1368px) and (max-width:1520px) {

  .tc__card {
    width: 140px !important;
  }

  .tc__name {
    width: 140px;
  }


}

@media (min-width:1520px) and (max-width:1920px) {

  .tc__card {
    width: 150px !important;
  }

  .tc__name {
    width: 150px;
  }


}
/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .forSafari
    {
      margin-top: 6px !important;
    }
  
}}
.select__select {
  width: 100%;
  position: relative;
  outline: none;
}
.select__input-error {
  border: 1px solid var(--error-messages) !important;
}
.select__select_main {
  line-height: 15px;
  mix-blend-mode: normal;
  color: #1e2447;
  outline: none;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid rgba(30, 36, 71, 0.15);
  border-radius: 4px;
  padding: 7px 10px;
  cursor: pointer;
}
.select__select_main_selected {
  border: 1px solid rgba(30, 36, 71, 0.4);
}
.select__select_main input {
  width: 92%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(187, 189, 201, 0.4);
  text-transform: capitalize;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
}
.select__select_main_selected input {
  color: rgba(30, 36, 71, 0.7);
}
.select__select-arrow {
  cursor: pointer; 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  
}
.select__list-container {
  position: absolute;
  z-index: 102;
  width: 100%;
  padding: 0;
  border: 0.5px solid rgba(30, 36, 71, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #ffffff;
  outline: none;
  max-height: 150px;
  overflow: auto;
}

.select__list-item {
  cursor: pointer;
  list-style: none;
  margin: 16px 12px;
  height: 16px;
  display: flex;
  align-items: center;
  color: #1E2447;
  display: flex;
}
.select__list-item h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize;
  mix-blend-mode: normal;
  opacity: 0.5;
}
.select__list-item svg {
  opacity: 0.4;
}
.select__list-item:hover h3 {
  opacity: 1;
}
.select__list-item:hover svg {
  opacity: 0.8;
}

.Applink {
  color: black;
}
.Applink:hover {
  color: black;
}

.topbar{
    top:0;
    width: 100vw;
    height: 20px;
    color:rgba(0, 0, 0, 1);
}
