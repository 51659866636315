.select__select {
  width: 100%;
  position: relative;
  outline: none;
}
.select__input-error {
  border: 1px solid var(--error-messages) !important;
}
.select__select_main {
  line-height: 15px;
  mix-blend-mode: normal;
  color: #1e2447;
  outline: none;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid rgba(30, 36, 71, 0.15);
  border-radius: 4px;
  padding: 7px 10px;
  cursor: pointer;
}
.select__select_main_selected {
  border: 1px solid rgba(30, 36, 71, 0.4);
}
.select__select_main input {
  width: 92%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(187, 189, 201, 0.4);
  text-transform: capitalize;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
}
.select__select_main_selected input {
  color: rgba(30, 36, 71, 0.7);
}
.select__select-arrow {
  cursor: pointer; 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  
}
.select__list-container {
  position: absolute;
  z-index: 102;
  width: 100%;
  padding: 0;
  border: 0.5px solid rgba(30, 36, 71, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #ffffff;
  outline: none;
  max-height: 150px;
  overflow: auto;
}

.select__list-item {
  cursor: pointer;
  list-style: none;
  margin: 16px 12px;
  height: 16px;
  display: flex;
  align-items: center;
  color: #1E2447;
  display: flex;
}
.select__list-item h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize;
  mix-blend-mode: normal;
  opacity: 0.5;
}
.select__list-item svg {
  opacity: 0.4;
}
.select__list-item:hover h3 {
  opacity: 1;
}
.select__list-item:hover svg {
  opacity: 0.8;
}
