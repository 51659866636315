.card {
  background-color: white;
}

button.BrainhubCarousel__arrows {
  width: 30px;
  height: 30px;
  margin: 82px 0px 82px 0px;
  object-fit: contain;
  border-color: grey !important;
  background-color: white;
}

button.BrainhubCarousel__arrowLeft {
  margin-left: 25px;
  padding-right: 5px;
}

button.BrainhubCarousel__arrowRight {
  padding-left: 5px;
  margin-left: -8px;
  margin-right: 15px;
}

button.BrainhubCarousel__arrows span {
  border-color: grey !important;
}

button.BrainhubCarousel__arrows:hover {
  background-color: white !important;
}

.tc__card {
  display: flex;
  height: 194px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 140px !important;
  margin-right: 30px !important;
  border-radius: 3px !important;
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .tc__card {
      display: flex;
      height: 180px;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      width: 140px !important;
      margin-right: 30px !important;
    }

  }
}

/* Test website on real Safari 11+ */

.tc__none {
  transform: translate(0%, -400%);
  font-size: 15px;
  font-weight: 800;
  color: #d3d3d3;
}

.tc__name {
  width: 140px;
  /* padding-bottom:10px; */
  padding-block: 8px;
  text-align: center;
  background-color: var(--dark-blue-90);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc__name-decommissioned {
  width: 150px;
  /* padding-bottom:10px; */
  padding-block: 8px;
  text-align: center;
  background-color: var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}


.tc__text {
  width: 125px;
  height: 16px;
  margin: 1px 0px;
  opacity: 0.9;
  font-family: Roboto;
  font-size: 13px;
  align-content: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: var(--icon-white);
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
}

@media (min-width:1025px) and (max-width:1290px) {
  .tc__card {
    width: 155px !important;
  }

  .tc__name {
    width: 155px;
  }
}

@media (min-width:940px) and (max-width:972px) {

  .tc__card {
    width: 165px !important;
  }

  .tc__name {
    width: 165px;
  }
}



@media screen and (max-width:792px) {

  .tc__card {
    width: 150px !important;
  }

  .tc__name {
    width: 150px;
  }
}

@media screen and (max-width:792px) {

  .tc__card {
    margin: 0 auto !important;
  }


}

@media (min-width:1368px) and (max-width:1520px) {

  .tc__card {
    width: 140px !important;
  }

  .tc__name {
    width: 140px;
  }


}

@media (min-width:1520px) and (max-width:1920px) {

  .tc__card {
    width: 150px !important;
  }

  .tc__name {
    width: 150px;
  }


}