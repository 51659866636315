.sd__box-measurement-details,
.sd__box-sensor-details {
  margin: 0;
  width: 100%;
  border-bottom: 1px solid rgba(30, 36, 71, 0.1);
  border-radius: 0px;
}
.sd__box-measurement-details header {
  padding: 20px 15px 0px;
  border-bottom: none;
}
.sd__box-sensor-details header {
  padding: 20px 25px 0px;
  border-bottom: none;
}
.sd__box-measurement-details header > h1,
.sd__box-sensor-details header > h1 {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #1e2447;
  margin-left: 0px;
}
.sd__box-sensor-details-container {
  padding: 14px 25px;
}
.sd__box-container {
  padding: 14px 20px 14px;
}
.sd__md-input-box {
  min-width: 47%;
  margin-bottom: 16px;
}
.sd__sd-input-box {
  width: 30% !important;
  min-width: 30%;
  margin-bottom: 16px;
}

.sd__date-root {
  width: 65px !important;
  border: 1px solid #eee;
}

.sd__date-root > div > div {
  width: 100%;
}

.sd__date-root > div > div > input {
  width: 100%;
}

.sd__date-root-expand {
  width: 100% !important;
  border: 1px solid #eee;
}

.sensor_inputs {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.sensor_inputs .db__input-wrap {
  width: 100% !important;
  margin-bottom: 0;
}

.sd__box header {
  border: 0px;
  padding: 0 25px;
}

.data-col-container,
.sensor-config-container,
.mounting-container,
.callibration-container {
  border-top: 1px solid #1e24471a;
}

.data-col-container .stats_col,
.data-col-container .col_col {
  max-width: 210px;
  min-width: 210px;
}

.data-col-container .stats_col {
  margin-right: 25px;
}

#right-preview-sidebar {
  transition: 0.5s ease-in-out;
}
