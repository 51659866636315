.rsb__towerSidePane {
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 53.6px;
  height: 100%;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.02);
  background-color: var(--icon-white);
}

.rsb__towerSidePane.width-wide {
  width: 695px;
}
.rsb__towerSidePane.width-narrow {
  width: 250px;
}

@media (max-width: 1600px) {
  .rsb__towerSidePane.width-wide {
    width: 550px;
  }
}
@media (max-width: 1200px) {
  .rsb__towerSidePane.width-wide {
    width: 400px;
  }
}
.rsb__towerSideSubPane {
  display: flex;
  flex-direction: column;
  padding: 25px 20px 0;
}

.towerLocationContainer {
  width: 100%;
  height: 200vh;
  display: flex;
  margin-top: 30px;
  color: white;
}
.towerNameSidePane {
  opacity: 0.3;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #1e2447;
}

.towerLocationPreview {
  margin-top: 5px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #1e2447;
}

.towerNo {
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bdbdbd;
  flex: 1;
}

.towerNo p {
  margin-top: 5px;
}
