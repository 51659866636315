:root {
  /* --dark: #0e1625;
  --primary-main: #1e2447;
  --white: #ffffff; */

  --dark: #0e1625;
  --white: #ffffff;
  --light-gray: #b1b1bd;
  --dark-blue: #1e2447;
  --error-messages: #ff647c;
  --main-cta: #00c48c;
  --darker-blue: #7b96f2;
  --dark-blue-95: #292e4f;
  --lighter-blue: #c4d0f9;
  --dark-blue-90: #333858;
  --dark-blue-85: #3e4461;
  --dark-blue-80: #4a4f6b;
  --faded-blue: #bbc1e4;
  --page-grey-bg: #f9f9f9;
  --grey: #c8c8c8;
  --dark-blue-75: #555973;
  --dark-blue-70: #5f637c;
  --dark-blue-65: #6b6e85;
  --dark-blue-60: #767a8f;
  --dark-blue-55: #818497;
  --dark-blue-50: #8b8ea0;
  --dark-blue-45: #9799a9;
  --dark-blue-40: #a2a4b2;
  --dark-blue-30: #b7b9c3;
  --dark-blue-25: #c3c4cd;
  --dark-blue-15: #d8d9df;
  --dark-blue-20: #cecfd6;
  --dark-blue-10: #e3e4e7;
  --dark-blue-9: #e6e6e9;
  --dark-blue-8: #e8e9ec;
  --dark-blue-7: #eaebed;
  --dark-blue-5: #eeeff1;
  --dark-blue-6: #ededef;
  --dark-blue-4: #f1f1f3;
  --dark-blue-3: #f3f3f4;
  --dark-blue-2: #f6f6f6;
  --dark-blue-1: #f7f7f8;
  --left-side-bar-bg-color: #111b30;
  --timeline-darker-blue: #8e9cea;
  --timeline-lighter-blue: #c2ccff;
  --timeline-general-maintenance: #fc8761;
  --timeline-height-change: #53b09f;
  --styles-brand-theme-accent-action-pri: #37b24d;
  --colors-15-white-subnav-buttons: rgba(255, 255, 255, 0.15);
  --styles-dark-light-theme-action-secon: rgba(5, 61, 109, 0.06);
  --timeline-orientation-change: #b08cf8;
  --primary-bg: #111b30;

  /* custom */
  --page-grey-bg: #f9f9f9;
  --icon-white: #ffffff;
  --jexcel_header_color: #000;
  --jexcel_header_color_highlighted: #000;
  --jexcel_header_background: #f3f3f3;
  --jexcel_header_background_highlighted: #dcdcdc;
  --jexcel_content_color: #000;
  --jexcel_content_color_highlighted: #000;
  --jexcel_content_background: #fff;
  --jexcel_content_background_highlighted: rgba(0,0,0,0.05);
  --jexcel_menu_background: #fff;
  --jexcel_menu_background_highlighted: #ebebeb;
  --jexcel_menu_color: #555;
  --jexcel_menu_color_highlighted: #555;
  --jexcel_menu_box_shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  --jexcel_border_color: #ccc;
  --jexcel_border_color_highlighted: #000;
  --active_color: #007aff;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  font-size: 62.5%;
  overflow: hidden;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  /* line-height: 1.5; */
  font-family: "Roboto", sans-serif;
}

/* img {
  display: block;
  width: 100%;
} */
fieldset,
input {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

button:focus {
  border: none;
  outline: none;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img {
  max-width: 100%;
  display: block;
} */

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url("assets/icons/calendar.svg") no-repeat;
  width: 13px;
  padding: 0;
  margin: 0;
  padding-right: 7px;
  height: 13px;
  border-width: thin;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cp__modal,
.MuiDialog-root {
  z-index: 10000000000 !important;
}

.no-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.no-info-icon {
  width: 130px;
  height: 130px;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.no-info-text {
  font-size: 14px;
  color: #c1bebe;
  margin-bottom: 20px;
  text-align: center;
}

.green-select .select__select_main {
  border: 1.5px solid #00c48c;
  background-color: transparent;
  padding: 0px;
}

.green-select input {
  padding: 7px 10px;
}

.green-select input,
.green-select input::placeholder {
  color: #00c48c !important;
}

.green-select svg > g > path {
  fill: #00c48c;
}

.add-box-attribute-container {
  border: 0.8px dashed rgba(30, 36, 71, 0.7);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
}

html p {
  margin-bottom: 0;
}

html * {
  font-family: "Roboto", sans-serif;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6 {
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 10px;
}

.newerTableStyles .MuiDataGrid-window {
  overflow-y: visible !important;
  position: relative;
  overflow-x: hidden !important;
}

/* .newerTableStyles  */
.MuiDataGrid-root .MuiDataGrid-window {
  position: relative !important;
}

.SD_Datagrid .MuiDataGrid-window {
  top: 23px !important;
}
.SD_Datagrid .MuiDataGrid-windowContainer {
  height: fit-content !important;
}
.SD_Datagrid .MuiDataGrid-footerContainer {
  margin-top: 27px !important;
}
.SD_Datagrid #mui-74230 {
  font-size: 15px !important;
}

.newerTableStyles .MuiDataGrid-window::-webkit-scrollbar {
  width: 55px;
}

.newerTableStyles .MuiDataGrid-windowContainer {
  height: auto !important;
}

.newTableStyles .MuiDataGrid-dataContainer,
.newTableStyles .MuiDataGrid-viewport {
  height: auto !important;
}

.newTableStyles .MuiDataGrid-viewport {
  max-height: none !important;
}

.newTableStyles .MuiDataGrid-renderingZone {
  max-height: none !important;
  transform: none !important;
}

.columnCellStyles {
  display: flex;
  line-height: initial !important;
  flex-direction: column;
}

.newRowStyles {
  max-height: none !important;
  align-items: stretch;
}

.newCellStyles {
  max-height: none !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex !important;
  align-items: center !important;
  line-height: 14px;
  justify-content: center !important;
  width: 100%;

  /* identical to box height, or 108% */
  text-align: center;
  letter-spacing: 0.13125px;
  color: #1e2447;
  opacity: 0.85;
}

.newTableStyles.newerTableStyles {
  border: none !important;
}

.newTableStyles.newerTableStyles .MuiDataGrid-columnsContainer {
  background-color: #111b30;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
}

.newerTableStyles.collapsedTable .MuiDataGrid-columnsContainer {
  width: 100% !important;
}

.newHeaderStyles.newerHeaderStyles svg {
  color: #fff;
}

.newHeaderStyles .MuiDataGrid-columnHeaderTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #1e2447;
}

.newCellStyles.align-left {
  justify-content: flex-start !important;
}

.newTableStyles.newerTableStyles .newRowStyles {
  flex-wrap: wrap;
}

.w-100 {
  width: 100% !important;
}

.no-min-max {
  min-width: auto !important;
  max-width: none !important;
  min-height: auto !important;
  max-height: none !important;
}

.w-0 {
  width: 0 !important;
}

.newHeaderStyles.newerHeaderStyles .MuiDataGrid-columnHeaderTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
  /* identical to box height, or 100% */

  color: #fff;
}

.newHeaderStyles .MuiDataGrid-columnSeparator {
  display: none;
}

.newHeaderStyles.newerHeaderStyles .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px;
}

.newHeaderStyles .MuiDataGrid-iconButtonContainer {
  align-items: center;
}

.newHeaderStyles {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-height-table.MuiDataGrid-root {
  flex: 0 0 56px;
  overflow: hidden;
}

.cursorPointer {
  cursor: pointer;
}

.newCellStyles.align-right {
  justify-content: flex-end !important;
}

.submit-btn {
  padding: 9px 35px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-cancel {
  color: rgba(30, 36, 71, 0.5);
  background: #eff0f2;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.green-select::before {
  height: 100%;
  border-bottom: 1px solid #00c48c !important;
  /* margin-left: -8px; */
  border: 1px solid #00c48c;
}

.green-select::after {
  border-bottom: none !important;
}

.green-select {
  color: #00c48c !important;
}

.green-select > .MuiSelect-icon {
  color: #00c48c;
}

.selectedRowsStyles {
  background: #fff !important;
}

.auto-complete-input-label label {
  color: #000 !important;
}

.auto-complete-input-label-1 {
  border: 0;
}

.auto-complete-input-label-1 label {
  color: #000 !important;
  font-size: 13px;
}

.auto-complete-input-label-1 input {
  color: #000 !important;
  font-size: 13px;
}

.auto-complete-list-items li {
  color: #1e2447;
  opacity: 0.5;
  font-size: 14px;
}

.auto-complete-list-items li:hover {
  opacity: 1;
}

.sentry-error-embed .form-submit .powered-by {
  display: none !important;
}

.Container404 {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.Heading404 {
  font-size: 45px;
}

#homeLink404 {
  margin-top: 15px;
  font-size: 18px;
  text-decoration: none;
}
.marker-labelx {
  background-color: white;
  margin-top: 65px;
  padding: 4px 7px;
  font-size: 15px;
  font-weight: bold;
  color: "#000000";
}
.ExceptionWidth {
  width: 47% !important;
}
.ExceptionWidth48 {
  width: 48% !important;
}
.ExceptionWidth100 {
  width: 100% !important;
  margin-top: 0px !important;
}


.submit-btn {
  background: #00c48c;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-cancel {
  /* margin-left: 10px; */
  background: #eff0f2;
}

.form_section {
  margin: 21px 25px !important;
  background: #ffffff;
  border-radius: 6px;
}
.header-button{
  border: 1px solid;
    padding: 3px 5px;
    border-radius: 5px;
    font-weight: 500;
    color: #1E2447 !important;
    display: flex;
    opacity: 1 !important;
    align-items: center;
}
.header-menu{
  padding: 3px 0px !important;
  display: flex !important;
  gap: 5px !important;
  margin: 3px 0px !important;
  font-size: 13px !important;
  min-width: 90px !important;
}
.header-menu a{
  text-decoration: none !important;
  color: black !important;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0px 8px;
  white-space: break-spaces;
  gap: 5px;
  margin-block: 2px;  

}

.header-menu svg{
  min-width: 20px;
}

.header-menu svg path, .header-button svg path{
  stroke: black !important;
  fill: black !important;
}
.ps__box-center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.prism{
  height: 33px;
  filter: invert(1);
  opacity: 0.6;
  margin-bottom: -5px;
  margin-top: -8px;
}