.ma__list-wrap {
  border: 1px solid rgba(30, 36, 71, 0.15);
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  transition: height 0.3s;
  overflow: hidden;
  flex-direction: column;
  padding: 0;
  margin-bottom: 6px;
}
.ma__list-expand {
  height: 100%;
  border-radius: 2px 2px 6px 6px;
  background: #f9f9f9;
}
.ma__list-close {
  height: 34px !important;
}

.ma__list-expand-more {
  height: 590px;
}

.ma__date-root-style {
   background-color: rgba(30, 36, 71, 0.06);
}

.ma__delete-btn {
  display: flex;
  align-self: start;
  border: none;
  outline: none;
  padding: 0px 20px 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.ma__delete-btn > span {
  margin-left: 4px;
  color: #ff647c;
  opacity: 0.7;
  font-size: 11px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: normal;
}

.ma__delete-btn-container{
  display: flex;
  /* justify-content: center; */
}

.ma__input-box{
  width: 33%;
} 

.ma__submit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 20px;

}

.ma__submit-btn {
  padding: 9px 35px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ma__submit-cancel {
  margin-left: 10px;
  color: rgba(30, 36, 71, 0.5);
  background: #eff0f2;
}