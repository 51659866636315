.db_section {
  /* display: flex; */
  flex: 1;
  margin: 21px 25px;
  background: #ffffff;
  border-radius: 6px;
  height: fit-content;
}

.db_section article {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.db_section header {
  border-bottom: 1px solid rgba(30, 36, 71, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.no-header header {
  border: 0;
  padding: 0;
}

.db__formInput {
  display: flex;
  flex: 3;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  padding: 21px 25px;
}

.db__formInput.sca__form {
  justify-content: flex-start;
}

.db__input-wrap {
  /* height: 40px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 19px;
  width: 90%;
}

.db__input-wrap p {
  margin-bottom: 0;
  /* position: absolute;
  margin-top: 35px; */
}

.db__input-wrap-view-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.1px;
  color: #1e2447;
  opacity: 0.3;
  margin-bottom: 6px;
}

.db__input-wrap-edit-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 6px;
}

.db__input-inner {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  flex-direction: column;
}

.db__input-inner2 {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

.db__input-view {
  background-color: transparent;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #1e2447;
  opacity: 0.9;
  text-overflow: ellipsis;
}

.db__input2 {
  display: flex;
  color: #000000;
  opacity: 0.9;
  padding-left: 0;
  padding-right: 0;
  width: 600px;
  height: 100%;
  margin: 0;
  padding: 6px 0 6px 8px;
  outline: none;
  border: unset;
  resize: none;
  background: rgba(30, 36, 71, 0.06);
  border-radius: 2px;
  vertical-align: start;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.db__input {
  display: flex;
  color: #000000;
  opacity: 0.9;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 6px 0 6px 8px;
  outline: none;
  border: unset;
  resize: none;
  background: rgba(30, 36, 71, 0.06);
  border-radius: 2px;
  vertical-align: start;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.db__input-error {
  border: 1px solid var(--error-messages) !important;
}

.db__upload {
  display: flex;
  flex: 1;
  width: 100%;
  height: 42px;
  background: #ffffff;
  /* border: 1px solid rgba(30, 36, 71, 0.15); */
  border-radius: 2px;
  justify-content: flex-start;
  align-items: center;
}
.bor {
  border: 1px solid rgba(30, 36, 71, 0.15);
}
.db__upload > p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  opacity: 0.8;
  margin-left: 10px;
}

@media (max-width: 1500px) {
  .db__input-wrap {
    margin-bottom: 19px;
  }
}

.progress {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}
