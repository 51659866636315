.gm-style .gm-style-iw-c {
  padding: 15px 0 15px 15px !important;
  width: 210px !important;
  max-height: 135px !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.18) !important;
}
.gm-style .gm-style-iw-d {
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
}
.gm-style .gm-style-iw-c button {
  top: -1.5px !important;
  right: 0 !important;
}
.ProjectName {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1E2447;
}
.ProjectType {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #1E2447;
  margin: 8px 0;
  text-transform: capitalize;
}
.location {
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}
.map__project-link {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.map__project-link span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.1px;
  color: #00C48C;
  margin-right: 6px;
}
.map__loader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
