    .header__dropdown {
        position: relative;
        display: inline-block;
    }
    
    .header__dropdown-content {
        display: none;
        position: absolute;
        min-width: 130px;
        z-index: 100000;
        background: #FFFFFF;
        mix-blend-mode: normal;
        border: 0.5px solid rgba(30, 36, 71, 0.15);
        box-sizing: border-box;
        box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        right: 0;
    }
    
    .header__dropdown-content {
        padding: 0;
        opacity: 1!important;
    }
    
    .header__dropdown-content li {
        padding: 10px 10px;
        text-decoration: none;
        display: block;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #1E2447;
        mix-blend-mode: normal;
        opacity: 0.4;
        cursor: pointer;
    }
    
    .header__dropdown-content li:hover { color: black; opacity: 1;}
    
    .header__dropdown:hover .header__dropdown-content {display: block; opacity: 1;}
    