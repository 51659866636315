.sca__list-wrap {
  border: 1px solid rgba(30, 36, 71, 0.15);
  border-radius: 2px;
  display: flex;
  height: 34px;
  overflow: hidden;
  flex-direction: column;
  /*align-items: space-between;*/
  padding: 0;
  /*margin-right: px;*/
}

.sc__body2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
/* 
.sca__list-wrap:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
} */
.sca__saved {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sca__saved > h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0 6px;
}
.sca__list-expand {
  /* transition: height 3s ease-in-out; */
  height: auto !important;
  border-top: none;
  /* border-left: 0.2px solid rgba(30, 36, 71, 0.15);
  border-right: 0.2px solid rgba(30, 36, 71, 0.15);
  border-bottom: 0.2px solid rgba(30, 36, 71, 0.15); */
  border-radius: 2px 2px 6px 6px;
  border: none !important;
}
.calibration-InnerContainer {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  flex-wrap: wrap;
}
.sca__form {
  display: flex;
  padding: 20px;
  width: 100%;
  /* justify-content: space-between; */
  gap: 0;
  column-gap: 21px;
}
.sca__form-flex {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;

  /* justify-content:; */
}
.sc__delete-cal {
  margin-top: 10px;
}
.ca__input-box {
  /* margin-top: 10px; */
  width: 100%;
  /* height: 40px; */
  /* width: 350px; */
}
.ca__input-box2 {
  /* margin-top: 10px; */
  width: 180px;
}
/*.sca__grey-input {*/
/*  width: 150px;*/
/*}*/

.sca__cal-input {
  border: 2px solid #eee;
  background-color: rgba(30, 36, 71, 0.06);
  width: 180px;
}
.sca__cal-input2 {
  background-color: rgba(30, 36, 71, 0.06);
  width: 150px;
}

.sca__input-box-gr {
  width: 500px !important;
}

.sca__box {
  margin: 0 !important;
  background: inherit;
}

.sca__form > div:not(:last-child, .ExceptionWidth, .ExceptionWidth48, .ExceptionWidth100) {
  width: 30% !important;
  min-width: 0 !important;
}


.sca__form-collapsed.sca__form > div:not(:last-child) {
  /*width: calc(100% - 12.5px) !important; */
  /* width: 100% !important; */
}

.input-textarea-full {
  width: 100%;
}
.sca__form > .db__input-wrap.tm__input-box-full {
  width: 100% !important;
}

.ca__textarea-box {
  margin-top: 10px;
  width: 1100%;
  height: 40px;
}
.inputMargin {
  margin-bottom: 20px;
}
.db__input > .tm__input-box-full.ca__input-box {
  margin-top: 10px;
  width: 1100%;
  height: 40px;
}
.sca__form > .db__input {
  width: 100% !important;
}

.sca__form.sca__form-collapsed > .db__input-wrap.tm__input-box-full {
  width: 79.5% !important;
  height: auto;
  margin-top: 10px;
}
.sca__form.sca__form-collapsed > .db__input-wrap.tm__input-box-full .db__input-inner textarea {
  /* width: 1000px; */
  /* max-width: 500%;
  min-width: 100%; */
  /* height: 108px; */
  width: 1230%;
  height: 30px;
}
.sca__upload-documentation {
  margin-bottom: 7px;
  width: 100% !important;
}
.sca__upload-documentation .db__input-inner {
  align-items: center;
  /* width: 85%; */
}
.sca__upload-documentation .container {
  margin-bottom: 0px;
}
.sca__upload-documentation label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sca__upload-documentation label > button {
  outline: none;
  border: none;
  background: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.sca__upload-documentation label > button > span {
  margin-left: 8px;
}
/* .sca__upload-documentation .db__input-inner2 {
  align-items: center;
  width: 105%
} */
.sca__upload-documentation2 {
  margin-bottom: 7px;
  width: 100% !important;
  background-color: #fff;
}
.sca__upload-documentation2 .db__input-inner {
  align-items: center;
  /* width: 110%; */
}
.sca__upload-documentation2 .container {
  margin-bottom: 0px;
}
.sca__upload-documentation2 label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sca__upload-documentation2 label > button {
  outline: none;
  border: none;
  background: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.sca__upload-documentation2 label > button > span {
  margin-left: 8px;
}

.sca__delete-button {
  height: 100%;
  margin-left: 4px;
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
}

.sca__delete-button > svg {
  font-size: 18px;
}

.activeBox .db__input-inner {
  border: 1px solid #00c48c;
}
.activeBox .db__upload > p {
  color: #00c48c;
  font-size: 14px;
}

.activeBox .container {
  margin-bottom: 0;
}

.activeBox .db__upload {
  height: auto;
  justify-content: flex-start;
  padding: 10px;
}

.updated-at-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.ca__submit-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 20px;
}
.ca__submit {
  width: 70%;
  display: flex;
  align-items: center;
}

.ca__submit-btn {
  position: relative;
  padding: 9px 35px;
  background: #00c48c;
  border-radius: 4px;
  height: 34px;
  text-align: center;
  border: #00c48c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.ca__submit-cancel {
  margin-left: 10px;
  background: #eff0f2;
  color: rgba(30, 36, 71, 0.5);
}
.ca__delete-btn {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px 20px 20px;
}
.ca__delete-btn > span {
  margin-left: 4px;
  color: #ff647c;
  opacity: 0.7;
  font-size: 11px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: normal;
}
#TextAreaIdHeight {
  height: 59px;
}

.ca__input-box-checkbox .db__input-inner{
  width: unset;
}

.ca__input-box-checkbox .db__input-inner span{
  /* width: 100%;
   */
   padding: 3px;
}
/* .sca__form-flex > div:not(:last-child) {
  width: 18% !important;
  min-width: 0 !important;
} */
